import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ReportsService as reportService } from '../reports/reports.service';
import { AgenciesService } from "../agencies/agencies.service";
import { SchoolsService } from '../schools/schools.service';
import { StaffService } from '../staff/staff.service';
import * as moment from "moment";
import { DatePipe } from "@angular/common";
import { Components } from "@ionic/core";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'bulk-reports',
  templateUrl: './bulk-reports.component.html',
  styleUrls: ['./bulk-reports.component.scss'],
})
export class BulkReportsComponent implements OnInit {
  public reportForm: FormGroup;
  @Input() modal: Components.IonModal;
  // @Input() staff;

  reportName: any;
  loading: boolean;
  Reports: any[]; 
  staffId:any;
  report: any;
  selectedMCC: any;
  disableStartEnd= true;
  start: any = null;
  end: any = null;
  startMinDate: Date = null;
  startMaxDate: Date = new Date();
  endMinDate: Date = null;
  endMaxDate: Date = null;
  fiscalYearList: any[];
  agencies: any[];
  schools: any[];
  activitiesList: any = [];
  masterCalendarList: any[];
  selectAgencyFormControl = new FormControl();
  searchAgencyInputTxt = new FormControl('');
  searchSchoolInputTxt = new FormControl('');
  isSchoolsListUpdated: boolean = false;

  selectSchoolFormControl = new FormControl();
  filteredAgencyOptions: Observable<any[]>;
  multipleActivities = true;
  selectedAgencyIds: any;
  selectedSchoolIds: any;
  displayMultipleSelection:boolean=false;
  generateReportText: string="Generate Report";
  agenciesList: any;
  schoolsList: any[];
  selectedSchool: any[];
  selectedAgency: any[];

  @ViewChild("searchAgency", { static: false }) searchAgencyTextBox: ElementRef;
  @ViewChild("searchSchool", { static: false }) searchSchoolTextBox: ElementRef;
  displayMultipleSchoolSelection: boolean=true;
  filteredSchoolOptions: any;
  disableActivities: boolean=false;
  tempActivitiesList: any[];
  disableAgency: boolean=false;
  constructor(
    private reportService: reportService,  
    public formBuilder: FormBuilder,
    public agenciesService: AgenciesService,
    public schoolsService: SchoolsService,
    public staffService: StaffService,

  ) {
    this.reportForm = formBuilder.group({
      agencies: [[], [Validators.required]],
      schoolId: [[], [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      masterCalendarId: ["0", Validators.required],
      fiscalYearId: [0, Validators.required],
    });
  }

  fetchData() {
    // Called when clicking on a specific report
    this.loading = true;
    this.reportService.getReports().subscribe((data: any) => {
      console.log("fetchData(): data from service:", data);
      this.Reports = data;
      this.loading = false; 
    });
  }

    // fetch agency data
    fetchAgencyList() {
      console.log("Fetch agency list called: ");
      
      this.agenciesService.getAgencies().subscribe((data: any) => {
        console.log("fetchAgencyList(): data from agencies service:", data);
        
        this.agenciesList = data
          .filter((x) => x.isActive)
          .sort((a, b) => a.name.localeCompare(b.name));
        console.log("The agency list is:", this.agenciesList);
        
        if (this.report.reportName.includes("Student Attendance Audit Report") && this.hasRoles([6, 7, 8])) {
          this.disableAgency = true;
          const agencies = [];
          this.displayMultipleSelection = false;
          agencies.push(this.profile.agency);

          this.reportForm.get("agencies").patchValue(agencies);
          this.fetchSchools(this.profile.agency);
        }
        if (this.displayMultipleSelection) {
          this.agenciesList.unshift({
            id: -1,
            name: "All Agencies",
            shortCode: "All Agencies",
          });
          let agencyEvent = {
            isUserInput: true,
            source: { selected: true, value: "All Agencies" },
          };

          this.selectionAgencyChange(agencyEvent);
        }
       
    
        this.filteredAgencyOptions = this.searchAgencyInputTxt.valueChanges.pipe(
          startWith<string>(""),
          map((name) => this._agencyFilter(name))
        );
      });
    }
    
  private _agencyFilter(name: string): String[] {
    const filterValue = name.toLowerCase();
    this.setAgencySelectedValues();
    this.selectAgencyFormControl.patchValue(this.selectedAgency);
    console.log("Agencies list: ",this.agenciesList)
    let filteredList = this.agenciesList.filter(
      (option) => option.name.toLowerCase().indexOf(filterValue) === 0
    );
    filteredList.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    var allAgencies = "All Agencies";
    filteredList.unshift(
      filteredList.splice(
        filteredList.findIndex((item) => item.name === allAgencies),
        1
      )[0]
    );
    return filteredList;
  }
    
  onMCChange(event) {
    this.selectedMCC = event[0].data.masterCalendarCode;
    console.log("The Master Calendar Code is",this.selectedMCC)
    this.disableStartEnd = false;
    this.start = null;
    this.end = null;
    this.startMinDate = moment("01/01/1000").toDate();
    this.startMaxDate = moment(event[0].data.fromDate).toDate();
    this.endMinDate = this.startMinDate;
    this.endMaxDate = this.startMaxDate;
    this.start = moment(event[0].data.fromDate).format("MM/DD/YYYY");
    this.end = moment(event[0].data.toDate).format("MM/DD/YYYY");
    // this.reportForm.get("startDate").setValue(this.start);
    // this.reportForm.get("endDate").setValue(this.end);
    this.fetchAgencyList();
  }
  cancelReports() {
    this.modal.dismiss("cancel");
    // this.modal.dismiss({ 'cancel': true });
  }




  onAgencyChange(event){
    this.agencies=[];
    event.forEach((element) => {
      this.agencies.push(element.data.id);
    });
    this.reportForm.get("agencies").setValue(this.agencies, { emitEvent: false });
    this.fetchSchools(this.agencies[0]);
  }
  
  onSchoolChange(event){
    this.schools=[]
    event.forEach((element) => {
      this.schools.push(element.data.id);
    });
    this.reportForm.get("schoolId").patchValue(this.schools);
    this.fetchActivities(this.schools[0]);
  }
  onActivityChange(event) {
    this.tempActivitiesList = [];
    event.forEach((element) => {
      this.tempActivitiesList.push(element.data.activityId);
    });
  }
  onSchoolChangeMultiSelect(selectedEvent) {
    var name=this.report.reportName;
    let school = this.schoolsList.filter(
      (x) => x.schoolName === selectedEvent.source.value
    );
    let event = [{ data: { id: school[0].id } }];
    let selectedSchoolIds =
      this.displayMultipleSelection == true
        ? this.schoolsList
            .filter((x) =>
              this.selectSchoolFormControl.value.includes(x.schoolName)
            )
            .map((x) => {
              return x.id;
            })
        : school[0];
    this.reportForm.get("schoolId").patchValue(selectedSchoolIds);
    this.fetchActivities(event[0].data.id);
  }
  selectionAgencyChange(event) {
    console.log("Selection agency change called");
    this.selectedSchool = [];
    this.selectSchoolFormControl.patchValue(this.selectedSchool); 

    if (!this.selectedAgency) {
        this.selectedAgency = []; // Ensure it is initialized
    }

    if (event.isUserInput) {
        if (event.source.selected) {
            if (event.source.value === "All Agencies") {
                this.selectedAgency = this.agenciesList.map((x) => x.shortCode);
            } else {
                this.selectedAgency.push(event.source.value);
            }
        } else {
            if (event.source.value === "All Agencies") {
                this.selectedAgency = [];
            } else {
                const index = this.selectedAgency.indexOf(event.source.value);
                if (index > -1) {
                    this.selectedAgency.splice(index, 1);
                }
                const allIndex = this.selectedAgency.indexOf("All Agencies");
                if (this.selectAgencyFormControl.value.includes("All Agencies")) {
                    this.selectedAgency.splice(allIndex, 1);
                }
            }
        }
        this.selectAgencyFormControl.patchValue(this.selectedAgency);
        this.filteredAgencyOptions = this.searchAgencyInputTxt.valueChanges.pipe(
          startWith<string>(""),
          map((name) => this._agencyFilter(name))
        );
        this.onAgencyChangeMultiSelect(event);
    }
}

  onAgencyChangeMultiSelect(selectedEvent) {
    let agency = this.agenciesList.filter(
      (x) => x.shortCode === selectedEvent.source.value
    );
    let event = [{ data: { id: agency[0].id } }];
    const agencies = [];
    event.forEach((element) => {
      agencies.push(element.data.id);
    });
    this.selectSchoolFormControl.patchValue(this.selectedSchool);
    let selectedAgencyIds =
      this.displayMultipleSelection == true
        ? this.agenciesList
            .filter((x) =>
              this.selectAgencyFormControl.value.includes(x.shortCode)
            )
            .map((x) => {
              return x.id;
            })
        : agencies[0];
    this.reportForm.get("agencies").patchValue(selectedAgencyIds);
    this.filteredAgencyOptions = this.searchAgencyInputTxt.valueChanges.pipe(
      startWith<string>(""),
      map((name) => this._agencyFilter(name))
    );
    this.fetchSchools(event[0].data.id);

  }

  openedAgencyChange(e) {
    this.searchAgencyInputTxt.patchValue("");
    if (e == true) {
      this.searchAgencyTextBox.nativeElement.focus();
    }
  }
  openedSchoolChange(e) {
    this.searchSchoolInputTxt.patchValue("");
    if (e == true) {
      this.searchSchoolTextBox.nativeElement.focus();
    }
  }

  setAgencySelectedValues() {
    if (
      this.selectAgencyFormControl.value &&
      this.selectAgencyFormControl.value.length > 0
    ) {
      this.selectAgencyFormControl.value.forEach((e) => {
        if (this.selectedAgency.indexOf(e) == -1) {
          this.selectedAgency.push(e);
        }
      });
      console.log("This .selected agnecies",this.selectedAgency)
    }
    console.log("select agency form control in set agency function:",this.selectAgencyFormControl)
  }
  setSchoolSelectedValues() {
    if (
      this.selectSchoolFormControl.value &&
      this.selectSchoolFormControl.value.length > 0
    ) {
      this.selectSchoolFormControl.value.forEach((e) => {
        if (this.selectedSchool.indexOf(e) == -1) {
          this.selectedSchool.push(e);
        }
      });
    }
  }


  selectionSchoolChange(event) {
    // this.selectedSchool = [];
    if (event.isUserInput) {
      if (event.source.selected == true && !this.isSchoolsListUpdated) {
        this.isSchoolsListUpdated = true;
        if (event.source.value === "All Schools") {
          this.selectedSchool = this.schoolsList.map((x) => x.schoolName);
        } else {
          if (!this.selectedSchool.includes(event.source.value)) {
            this.selectedSchool = [...this.selectedSchool, event.source.value];
          }
        }
        this.isSchoolsListUpdated = false;
    
      } else {
        if (event.source.value == "All Schools") {
          this.selectedSchool = [];
        } else {
          let index = this.selectedSchool.indexOf(event.source.value);
          this.selectedSchool.splice(index, 1);
          let allindex = this.selectedSchool.indexOf("All Schools");
          this.selectSchoolFormControl.value.includes("All Schools")
            ? this.selectedSchool.splice(allindex, 1)
            : "";
        }
      }
      this.selectSchoolFormControl.patchValue(this.selectedSchool);
      this.onSchoolChangeMultiSelect(event);
    }
  }

  getstaffidbyname(){
    this.staffService.getStaffInfoByLoginName(this.profile.name).subscribe(
      (res: any) => {
        console.log("This is the response from staff id",res);
      this.staffId=res;
        console.log("This is the response from staff id",res);
      },
      (error: any) => {
        console.error('Error fetching staff info:', error);
      }
    );
  }
  fetchSchools(id) {
    this.schoolsList = [];
    console.log("Select Agency form control:", this.selectAgencyFormControl);
  
    let selectedAgencyIds =
      this.displayMultipleSelection == true
        ? this.agenciesList
            .filter((x) =>
              this.selectAgencyFormControl.value.includes(x.shortCode)
            )
            .map((x) => {
              return x.id;
            })
        : id;
  
    if (this.displayMultipleSelection) {
      this.schoolsService
        .getSchoolsByAgencyIds(selectedAgencyIds)
        .subscribe((data: any) => {
          this.schoolsList = data.sort((a, b) =>
            a.schoolName.localeCompare(b.schoolName)
          );
  
          if (this.displayMultipleSchoolSelection) {
            this.schoolsList.unshift({ id: -1, schoolName: "All Schools" });
            this.reportForm.get("schoolId").setValue([-1]);

            let schoolEvent = {
              isUserInput: true,
              source: { selected: true, value: "All Schools" },
            };
          }
          this.filteredSchoolOptions =
            this.searchSchoolInputTxt.valueChanges.pipe(
              startWith<string>(""),
              map((name) => this._schoolFilter(name))
            );
        });

        
        
    } else {
      this.schoolsService.getSchoolsByAgency(id).subscribe((data: any) => {
        console.log("fetchAgencyList(): data from agencies service:", data);
        this.schoolsList = data.sort((a, b) =>
          a.schoolName.localeCompare(b.schoolName)
        );
  
        if (this.displayMultipleSchoolSelection) {
          this.schoolsList.unshift({ id: -1, schoolName: "All Schools" });
          // this.reportForm.get("schoolId").setValue([-1]);
          let schoolEvent = {
            isUserInput: true,
            source: { selected: true, value: "All Schools" },
          };
        }
      });
    }
  }
  private _schoolFilter(name: string): String[] {
    const filterValue = name.toLowerCase();
    this.setSchoolSelectedValues();
    this.selectSchoolFormControl.patchValue(this.selectedSchool);
    let filteredList = this.schoolsList.filter(
      (option) => option.schoolName.toLowerCase().indexOf(filterValue) === 0
    );
    filteredList.sort((a, b) =>
      a.schoolName.toLowerCase() > b.schoolName.toLowerCase() ? 1 : -1
    );
    var allschools = "All Schools";
    filteredList.unshift(
      filteredList.splice(
        filteredList.findIndex((item) => item.schoolName === allschools),
        1
      )[0]
    );
    console.log(
      "This is the filtered list: -------------------?" + filteredList
    );
    return filteredList;
  }
   // fetches user's profile details
   get profile() {
    return JSON.parse(
      window.localStorage.getItem("_ionicstorage/_ionickv/userSubject")
    );
  }

  hasRoles(roles: number[]): boolean {
    if (this.profile) {
      for (const aRole of this.profile.roles) {
        if (roles.includes(aRole)) {
          return true;
        }
      }
    }
    return false;
  }
  fetchMasterCalendars() {
    this.reportService.getMasterCalendarsForReports().subscribe((data: any) => {
      this.masterCalendarList = data.filter(x => x.isActive);
      console.log('generatereportpopup:fetchFiscalYear(): data from fiscalYear service:', this.fiscalYearList);
  
    });
  }

  //fetch activities
  fetchActivities(schoolId) {
    var masterCalendarCode = this.selectedMCC;
    this.selectedSchoolIds = schoolId;
    if (this.displayMultipleSelection) {
      this.selectedSchoolIds = this.schoolsList
        .filter((x) =>
          this.selectSchoolFormControl.value.includes(x.schoolName)
        )
        .map((x) => {
          return x.id;
        });

      this.selectedSchoolIds = this.selectedSchoolIds
        .map((e) => e.toString().replace(/\s/g, ""))
        .join(",");

      this.selectedAgencyIds = this.agenciesList
        .filter((x) => this.selectAgencyFormControl.value.includes(x.shortCode))
        .map((x) => {
          return x.id;
        });
        this.selectedAgencyIds = this.selectedAgencyIds
        .map((e) => e.toString().replace(/\s/g, ""))
        .join(",");
      
      }

      this.selectedAgencyIds = this.profile.agency;
    

    if (this.selectedMCC ) {
      this.agenciesService
        .getActivities(
          this.selectedAgencyIds,
          this.selectedSchoolIds,
          masterCalendarCode
        )
        .subscribe((data: any) => {
          console.log("this.activitiesList", data);
          this.activitiesList = data.map((item) => ({
            activityId: item.scheduledActivity.id,
            activityName: item.activityName + " - " + item.scheduledActivity.groupName,
          }));
        });
    }
  }
  



  // changeStartDate(event) {
  //   if (event == "Invalid Date") {
  //     this.reportForm.get("startDate").setValue("");
  //   } else if (event != "") {
  //     console.log(event);
  //     let inputDay = new Date(event);
  //     if (inputDay > this.startMaxDate) {
  //       this.reportForm.get("startDate").setValue(this.startMaxDate);
  //       return;
  //     }
  //     if (inputDay < this.startMinDate) {
  //       this.reportForm.get("startDate").setValue(this.startMinDate);
  //       return;
  //     }
 
  //       this.endMinDate = new Date(event);
  //       this.endMaxDate = new Date(event);
  //       this.endMaxDate.setDate(this.endMaxDate.getDate() + 60)
  //       this.reportForm.get("endDate").setValue(this.endMaxDate);
  //     } 
    
  // }

  // changeEndDate(event) {
  //   let inputDay = new Date(event);
  //   if (inputDay > this.endMaxDate) {
  //     this.reportForm.get("endDate").setValue(this.endMaxDate);
  //     return;
  //   }
  //   if (inputDay < this.endMinDate) {
  //     this.reportForm.get("endDate").setValue(this.endMinDate);
  //     return;
  //   }
  //   console.log(event);
  //   if (this.report.reportName.includes("Performance Summary") || this.report.reportName.includes("Student Attendance Audit Report")
  //   ) {
  //     this.startMaxDate = new Date(event);
  //   }
  // }


  changeStartDate(event) {
    if (event == "Invalid Date") {
      this.reportForm.get("startDate").setValue("");
    } else if (event != "") {
      console.log(event);
      let inputDay = new Date(event);
      let today = new Date();
      if (inputDay > today) {
        this.reportForm.get("startDate").setValue(today);
        return;
      }
      this.endMinDate = new Date(event);
      this.endMaxDate = today;
      if (this.reportForm.get("endDate").value && new Date(this.reportForm.get("endDate").value) > today) {
        this.reportForm.get("endDate").setValue(today);
      }
    }
  }
  
  changeEndDate(event) {
    let inputDay = new Date(event);
  
    // Ensure the date is not in the future or before the minimum start date
    let today = new Date();
  
    if (inputDay > today) {
      this.reportForm.get("endDate").setValue(today);
      return;
    }
    if (this.endMinDate && inputDay < this.endMinDate) {
      this.reportForm.get("endDate").setValue(this.endMinDate);
      return;
    }
  
    console.log(event);
  
    // Update startMaxDate for specific reports
    if (
      this.report.reportName.includes("Performance Summary") ||
      this.report.reportName.includes("Student Attendance Audit Report")
    ) {
      this.startMaxDate = new Date(event);
    }
  }
  

  //Insert here to display Master Calendar Code in the Report
  displayMCYear(){
    if(this.report.reportName=="Student Attendance Audit Report"){
      return true
        }
  }
  //Insert here to display Agency in the Report
  displayAgency(){
    if(this.report.reportName=="Student Attendance Audit Report" && this.hasRoles([6,7,8])){
      return true
        }
  }
  //Insert here to display Schools
  displaySchools(){
    if(this.report.reportName=="Student Attendance Audit Report" && this.hasRoles([6,7,8])){
      return true
        }
      
  }
  //Insert here to display Activities in the Report
  displayActivities(){
    if(this.report.reportName=="Student Attendance Audit Report"){
      return true
        }
  }

  //Insert here to display Start Date in the Report
  displayStart(){
    if(this.report.reportName=="Student Attendance Audit Report"){
      return true
        }
  }

  //Insert here to display End Date in the Report
  displayEnd(){
    if(this.report.reportName=="Student Attendance Audit Report"){
      return true
        }
  }
  //generate reports
  generateReports(report) {
    const pipe = new DatePipe('en-US');
    const requestReport = this.reportForm.value;
    requestReport.schools = requestReport.schoolId;
    requestReport.activities = this.tempActivitiesList;
    this.getstaffidbyname();
    requestReport.staffId = this.staffId.staff[0].staff.id;

    if (requestReport.activities === undefined) {
      requestReport.activities = [];
    }

    requestReport.reportId = report.id;
    requestReport.category = this.report.category;
    requestReport.staffAgency = this.profile.agency;
    requestReport.staffSchool = this.staffId.schools[0].id;;

    requestReport.reportName = this.report.reportName; console.log("Request Report is: ", requestReport);
    let startDate = null;
    let endDate = null;

    if (requestReport.startDate != null) {
      startDate = pipe.transform(requestReport.startDate, "yyyy-MM-dd");
      this.reportForm.get("startDate").setValue(startDate);
      requestReport.startDate = startDate;
    }

    if (requestReport.endDate) {
      endDate = pipe.transform(requestReport.endDate, "yyyy-MM-dd");
      this.reportForm.get("endDate").setValue(endDate);
      requestReport.endDate = endDate;
    }

    if (this.report.reportName.includes("Student Attendance Audit Report")) {
      this.reportService.getStudentAttendanceAuditReport(requestReport).subscribe();
      this.modal.dismiss("submitted");
    }
  }
  ngOnInit() {
      this.generateReportText="Schedule Report";
      this.getstaffidbyname();
      console.log("***************STAFF***********",this.staffId)
      if (!this.hasRoles([6,7,8])){
        this.displayMultipleSelection=true;
      }
      this.startMaxDate = new Date();
      this.endMaxDate = new Date();
      this.fetchMasterCalendars() 
      this.filteredAgencyOptions = this.searchAgencyInputTxt.valueChanges.pipe(
        startWith<string>(""),
        map((name) => this._agencyFilter(name))
      );
      this.filteredSchoolOptions =
      this.searchSchoolInputTxt.valueChanges.pipe(
        startWith<string>(""),
        map((name) => this._schoolFilter(name))
      );
  }
}
