import { Component, OnInit, Input, ViewChild, AfterViewChecked, HostListener } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { SchoolsService } from '../schools/schools.service';
import { StaffDetailsService } from '../staff-details/staff-details.service';
import { Components } from '@ionic/core';

@Component({
  selector: 'school-location',
  templateUrl: './school-location.component.html',
  styleUrls: ['./school-location.component.scss'],
})
export class SchoolLocationComponent implements OnInit {
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @Input() staff;
  @Input() assignedLocations;
  @Input() modal: Components.IonModal;
  sortedItems: any = [];
  schools: any = [];
  allSchoolsSelected = false; 
  agencyId: any;
  columnMode = ColumnMode.force;
  defaultSort = [{ prop: 'schoolName', dir: 'asc' }];
  innerWidthGreater: boolean;
  loading = false;

  constructor(
    private schoolsService: SchoolsService,
    public staffDetailsService: StaffDetailsService,
  ) { }

  onResize() {
    this.innerWidthGreater = window.innerWidth <= 728; 
    this.table.recalculate();
  }

  get sortedSchools() {
    return this.sortedItems;
  }
  get modifiedSchools() {
    return this.sortedItems.filter((s) => s.modified == true);
  }
  get assignedSchools() {
    return this.schools.filter((s) => s.selected == true);
  }

  ngOnInit() {
    this.loadSchools();    
  }

  loadSchools() {
    this.loading = true;
    console.log('Loading schools ...');
    this.schoolsService
      .getSchoolsByAgencyId(this.staff.agency.agencyId)
      .subscribe((schools) => {
        this.schools = schools.map((s) => {
          s.selected = false;
          s.modified = false;
          return s;
        });

        this.schools = this.schools.filter(school => !this.assignedLocations.some(location => location.schoolId === school.id));

        this.sortedItems = [...this.schools];
        console.log('loadSchools()...', this.schools);
        const that = this;
        setTimeout(function () {
          that.loading = false;
      }, 1000);
      });
  }

  getCellClass({ row, column, value }) {
    if (column.prop === 'schoolName' || column.prop === 'streetAddress' || column.prop === 'assigned') {
      return ' left-aligned-col';
    } else if (column.prop === 'action') {
      // 1800000
    } else if (column.prop === 'cost_Center_Code') {
      return ' center-aligned-col';
    } else {
      return '';
    }
  }

  selectAll() {
    this.schools.forEach((school) => {
      if (school.selected == false && school.modified == false) {
        school.modified = true;
      }
      school.selected = true;
    });
    this.allSchoolsSelected = true;
  }

  unselectAll() {
    this.schools.forEach((school) => {
      if (school.selected && school.modified) {
        school.modified = false;
      }
      school.selected = false;
    });
    this.allSchoolsSelected = false;
  }

  filterRows($event) {
    const val = $event.toLowerCase();
    if (val.length == 0) {
      this.sortedItems = this.schools;
      return;
    }
    if (val.length < 1) {
      return;
    }
    this.sortedItems = this.schools.filter((s) => s.schoolName.toLowerCase().indexOf(val) >= 0);
  }

  assignLocation(row: any) {
    row.modified = !row.modified;
  }

  cancel(){
    this.modal.dismiss('cancel')
  }

  addLocations() {
    var selectedSchools = this.schools.filter((s) => s.selected == true);
    var locations = selectedSchools.map((s) => Number(s.id));
    console.log("Selected locations array", locations);

    if(locations.length === 0){
      console.error('No locations selected');
      this.staffDetailsService.showError("No locations were selected", "Error updating staff locations");
      return; 
    }

    this.staffDetailsService.addStaffLocations(this.staff.id, locations)
      .subscribe(result => {
        console.log('Staff location status updated successfully:', result);
        this.modal.dismiss(); 
      },
      error => {
        console.error('Error updating staff locations:', error);
        this.staffDetailsService.showError("Error updating staff locations", error);
      });
  }

}
