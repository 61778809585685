// used on
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';
import { ToastController } from '@ionic/angular';
import { Router, ActivatedRoute, Params, Data } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AgenciesService {

  public idToken: string;
  newAgId: any;

  constructor(public httpClient: HttpClient, public storage: Storage, private route: ActivatedRoute,
    private router: Router, public toastController: ToastController) { }
  // fetch agencies
  getAgencies() {
    console.log('getAgencies()'); // todo: comment out logging as needed for prod
    return this.loadAgencies().pipe(map(this.processAgencies, this));
  }

  getAgenciesByMCC(mcc) {
    console.log('getAgenciesByMCC()'); // todo: comment out logging as needed for prod
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.agenciesUrl + 'agencylist/masterCalendarCode/' + mcc, { headers: header }).pipe(map(this.processAgencies, this));
  }

    // fetch Missed Attendance agencies
    getMissedAttendanceAgencies() {
      console.log('getMissedAttendanceAgencies()'); // todo: comment out logging as needed for prod
      return this.loadMissedAttendanceAgencies();
    }


    // fetch Missed Attendance agencies
    getDeleteAttendanceAgencies() {
      console.log('getDeleteAttendanceAgencies()'); // todo: comment out logging as needed for prod
      return this.loadDeleteAttendanceAgencies();
    }

  private loadAgencies() {
    console.log('loadAgencies() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.agenciesUrl, { headers: header });
  }

  private loadMissedAttendanceAgencies() {
    console.log('loadMissedAttendanceAgencies() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.agenciesUrl + 'attendanceretroAgencies', { headers: header });
  }

  private loadDeleteAttendanceAgencies() {
    console.log('loadDeleteAttendanceAgencies() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.agenciesUrl + 'deleteattendanceAgencies', { headers: header });
  }
  // fetch agencies that have not been assigned to an award
  // used on award details - assigned agencies
  getUnassignedAgencies(id) {
    console.log('getAgencies()'); // todo: comment out logging as needed for prod
    return this.loadUnassignedAgencies(id).pipe(map(this.processAgencies, this));
  }

  private loadUnassignedAgencies(id) {
    console.log('loadAgencies() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.agenciesUrl + 'unassigned/award/' + id, { headers: header });
  }
  // POST request of newly added agency to agencies endpoint
  createAgency(agency) {
    console.log('createAgency() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.post(environment.agenciesUrl, agency, { headers: header }).subscribe(
      result => {
        // Handle result
        console.log(result);
        this.newAgId = result;
      },
      error => {
        this.showError(error);
      },
      async () => {
        const toast = await this.toastController.create({
          color: 'success',
          duration: 3000,
          message: 'Success!',
          showCloseButton: true,
          position: 'top',
          buttons: [
            {
              side: 'end',
              text: 'Go To Newly Added Agency',
              handler: () => {
                this.goToAgency(Number(this.newAgId.id));
              }
            }
          ]
        });
        await toast.present();
        setTimeout(function () {
        }, 1500);
      }
    );
  }
  // creates link to newly added agency
  async goToAgency(id) {
    return this.router.navigateByUrl(
      '/agency-details/' +
      id
    );
  }
  // PUT request to update an agency
  updateAgency(agency, modal) {
    console.log('updateAgency() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });

    return this.httpClient.put(environment.agenciesUrl + agency.id, agency).subscribe(
      result => {
        // Handle result
        console.log(result);
        if (result === true) {
          this.showSuccess();
          if (modal) {
            modal.dismiss({ 'success': true });
          }
        }
      },
      error => {
        this.showError(error);
      }
    );
  }
  // DELETE request to agency endpoint to deactivate an agency
  deactivateAgency(agency) {
    console.log('deactivateAgency() with url: ', environment.agenciesUrl);
    return this.httpClient.delete(environment.agenciesUrl + agency.id).subscribe(
      result => {
        // Handle result
        console.log(result);
      },
      error => {
        this.showError(error);
      },
      () => {
        this.showSuccess();
      }
    );
  }
  // assign an agency to an award
  // used on award details - assigned agencies
  assignAgencyAward(agency: any, modal: any) {
    console.log('assignAgencyAward()'); // todo: comment out logging as needed for prod
    return this.addAgency(agency, modal);
  }

  private addAgency(agency, modal) {
    console.log('addAgency() with url: ', environment.agenciesUrl + 'assignagency');
    console.log(agency);
    return this.httpClient.put(environment.agenciesUrl + 'assignagency', agency).subscribe(function () {
      modal.dismiss('cancel');
      //  window.location.reload();
    });
  }

  processAgencies(data: any) {
    console.log('processAgencies() with', data.length, 'rows.');
    data.forEach((row: any) => {
      if (row.name) {
        row.status = row.isActive ? 'Active' : 'InActive';
        row.totalfundedamt = '1209';
      }
    });

    return data;
  }
  // fetch an awards assigned agencies
  getAgenciesForAward(awardId) {
    console.log('getAgenciesForAward()'); // todo: comment out logging as needed for prod
    return this.loadAgenciesForAward(awardId).pipe(map(this.processAgencies, this));
  }

  private loadAgenciesForAward(awardId) {
    console.log('loadAgencies() with url: ', environment.agenciesUrl + '/' + awardId + '/assignagency/');
    return this.httpClient.get(environment.agenciesUrl + '/' + awardId + '/assignagency/');
  }


  async showSuccess() {
    const toast = await this.toastController.create({
      color: 'success',
      duration: 2500,
      message: 'Success!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
    setTimeout(function () {
      // window.location.reload();
    }, 1500);
  }

  async showError(error) {
    console.log(error);
    const toast = await this.toastController.create({
      color: 'danger',
      duration: 2500,
      message: 'Error!',
      showCloseButton: true,
      position: 'top'
    });
    await toast.present();
  }
    //fetch activities list by agency school and master calendar
    getActivities(agencyId, schoolId, masterCalendarCode){
      var activitiesQuery = "scheduledActivities/agency/" + agencyId.toString() + "/school/" + schoolId.toString() + "/mastercalendar/" + masterCalendarCode.toString();
      console.log('getActivities(agencyId, schoolId, masterCalendarCode)'); // todo: comment out logging as needed for prod
    return this.loadActivities(activitiesQuery).pipe(map(this.processActivities, this));
    }
    loadActivities(activitiesQuery){
      console.log('loadActivities() with url: ', environment.activitiesUrl + activitiesQuery);
      return this.httpClient.get(environment.activitiesUrl + activitiesQuery);
    }
    processActivities(data: any){
      console.log('processActivities() with', data.length, 'rows.');
    return data;
    }
}


