// used for schools landing page, activity details, generate reports, calendar,
// school details, update award modal, update funding, update student
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class SchoolsService {

  public idToken: string;

  constructor(public httpClient: HttpClient, public storage: Storage) { }
  // fetch all schools
  getSchools() {
    console.log('getSchools()'); // todo: comment out logging as needed for prod
    return this.loadSchools().pipe(map(this.processSchools, this));
  }

    // fetch all schools
    getMissedAttendanceSchools() {
      console.log('getMissedAttendanceSchools()'); // todo: comment out logging as needed for prod
      return this.loadMissedAttendanceSchools();
    }

  private loadMissedAttendanceSchools() {
    console.log('loadMissedAttendanceSchools() with url: ', environment.agenciesUrl);
    this.storage.get('idToken').then(res => {
      this.idToken = res;
    });
    const header = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.idToken
    );
    return this.httpClient.get(environment.schoolsUrl + 'GetAttendanceretroSchools', { headers: header });
  }

      // fetch all schools
      getDeleteAttendanceSchools() {
        console.log('getMissedAttendanceSchools()'); // todo: comment out logging as needed for prod
        return this.loadDeleteAttendanceSchools();
      }

    private loadDeleteAttendanceSchools() {
      console.log('loadDeleteAttendanceSchools() with url: ', environment.agenciesUrl);
      this.storage.get('idToken').then(res => {
        this.idToken = res;
      });
      const header = new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.idToken
      );
      return this.httpClient.get(environment.schoolsUrl + 'GetDeleteAttendanceSchools', { headers: header });
    }

  private loadSchools() {
    console.log('loadSchools() with url: ', environment.schoolsUrl + 'all');
    return this.httpClient.get(environment.schoolsUrl  + 'all');
  }

  // fetch schools by agency
  getSchoolsByAgencyId(agencyId) {
    console.log('getSchoolsByAgencyId()'); // todo: comment out logging as needed for prod
    return this.loadSchoolsByAgencyId(agencyId).pipe(map(this.processSchools, this));
  }

   // fetch schools by agency
   getSchoolsByAgencyIds(agencyids) {
    console.log('getSchoolsByAgencyIds()'); // todo: comment out logging as needed for prod
    return this.httpClient.get(environment.schoolsUrl + 'agencylist/' + agencyids);
  }

  getSchoolsByAgencyIdsandByMCC(agencyids, mcc) {
    console.log('getSchoolsByAgencyIdsandbyMcc()'); // todo: comment out logging as needed for prod
    return this.httpClient.get(environment.schoolsUrl + 'agencylist/' + agencyids+ '/masterCalendarCode/' +mcc );
  }

  private loadSchoolsByAgencyId(agencyId) {
    console.log('loadSchoolsByAgencyId() with url: ', environment.schoolsUrl);
    return this.httpClient.get(environment.schoolsUrl + 'agency/' + agencyId + '/' + '?includeBranch=false');
  }

  // fetch schools by agency
  getSchoolsByAgencyAndRole(agencyId, roleId) {
    console.log('getSchoolsByAgencyAndRole()'); // todo: comment out logging as needed for prod
    return this.loadSchoolsByAgencyAndRole(agencyId, roleId).pipe(map(this.processSchools, this));
  }

  private loadSchoolsByAgencyAndRole(agencyId, roleId) {
    console.log('loadSchoolsByAgencyAndRole() with url: ', environment.schoolsUrl);
    return this.httpClient.get(environment.schoolsUrl + 'agency/' + agencyId + '/role/'+ roleId + '/' + '?includeBranch=false');
  }

  processSchools(data: any) {
    console.log('processSchools() with', data.length, 'rows.');
    return data;
  }
  // fetch school by agency ID
  getSchoolsByAgency(id, masterCalendarCode?) {
    console.log('getSchoolsByAgency()'); // todo: comment out logging as needed for prod
    if (masterCalendarCode) {
      return this.loadSchoolsByAgency(id, masterCalendarCode).pipe(map(this.processSchools, this));
    }
    return this.loadSchoolsByAgency(id).pipe(map(this.processSchools, this));
  }

  private loadSchoolsByAgency(id, masterCalendarCode?) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadSchoolsByAgency() with url: ', environment.schoolsUrl + 'byusername/' + profile.name + '/agency/' + id);
      return this.httpClient.get(environment.schoolsUrl + 'byusername/' + profile.name + '/agency/' + id);
    }
    else {
      console.log('loadSchoolsByAgency() with url: ', environment.schoolsUrl + 'agency/' + id);
      if (masterCalendarCode) {
        return this.httpClient.get(environment.schoolsUrl + 'agency/' + id + '/masterCalendarCode/' + masterCalendarCode);
      }
      return this.httpClient.get(environment.schoolsUrl + 'agency/' + id);
    }


  }
  // fetch school by agency ID and master calendar code
  getSchoolsByAgencyMC(agencyId, mcc){
    console.log('getSchoolsByAgency()'); // todo: comment out logging as needed for prod
    return this.loadSchoolsByAgencyMC(agencyId, mcc).pipe(map(this.processSchools, this));
  }

  private loadSchoolsByAgencyMC(agencyId, mcc) {
    const profile = JSON.parse(window.localStorage.getItem('_ionicstorage/_ionickv/userSubject'));
    const adminRoles: number[] = [7,8];

    if (adminRoles.includes(profile.roles[0])) {
      console.log('loadSchoolsByAgency() with url: ', environment.schoolsUrl + 'byusername/' + profile.name + '/agency/' + agencyId+'/mcc/'+mcc);
      return this.httpClient.get(environment.schoolsUrl + 'byusername/' + profile.name + '/agency/' + agencyId+'/mcc/'+mcc);
    }
    else {
      console.log('loadSchoolsByAgency() with url: ', environment.schoolsUrl + 'agency/' + agencyId+'/mcc/'+mcc);
      return this.httpClient.get(environment.schoolsUrl + 'agency/' + agencyId+'/mcc/'+mcc);
    }


  }

  getSchoolsByCds(cdsCodes: any) :Promise<any>{
    var array =  cdsCodes.map(s=>`cdsCodes=${s}`).join('&')
    return this.httpClient.get(`${environment.schoolsUrl}all`).toPromise();
  }

}
